import { getToken } from './auth-service';
import * as axios from 'axios';
// import { getSelectedCountry } from './services/country-info.service';
const FormData = require('form-data');

interface API_PARAMS {
    path: string;
    method?: string;
    data?: any;
    headers?: any;
}

const call = async (params: API_PARAMS,type?:string, isJson:boolean=false) => {
    const {path, method = 'GET', data = {}, headers ={}} = params;
    let form;
    const token = getToken();
    const extraConfig: any = {
        headers: { ...headers }
    };
    if (token) {
        extraConfig.headers.Authorization = token;
        extraConfig.headers.country_code = 'sa';
        // if(getSelectedCountry()){
        //     extraConfig.headers.country_code = getSelectedCountry();
        // }
    }
    if (method === 'POST'|| method === 'PUT') {
        if(isJson){
            extraConfig.data = data
        }else{
            form = new FormData();
            const dataKeys: any = Object.keys(data);
            for (const key of dataKeys) {
                // eslint-disable-next-line
                if (data[key] != undefined) {
                    form.append(key, data[key]);
                }
            }
            extraConfig.headers['Content-Type'] = 'multipart/form-data';
            extraConfig.data = form;
        }
    } else {
        extraConfig.params = data;
    }
    let url = '';
    switch(type){
        case 'sdd' : url = `${process.env.REACT_APP_SDD_API_BASE_URL}${path}`;
        break;
        case 'core' : url = `${process.env.REACT_APP_CORE_API_BASE_URL}${path}`;
        break;
        default : url = `${process.env.REACT_APP_SDD_API_BASE_URL}${path}`;
        break;
    }
   
    let response: any;
    try {
        response = await axios.default({
            method,
            url,
            ...extraConfig
        });
    } catch (e:any) {
        if (e.isAxiosError) {
            // if ([401, 403].indexOf(e?.response?.status) !== -1){
            //     localStorage.removeItem("token");
            //     localStorage.removeItem("user");
            //     history.push("/log-in");
            // }
            throw e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.message??e;
        }
        
        return e;
    }
    if ([200, 201].indexOf(response.status) === -1) {
        throw new Error(response.statusText).message;
    }
    return response.data;
};


export default call;