export const getStringError = (error: any) => {
    if(typeof error === "string"){
        return error;
    }
    let msg = "Unhandled exception";
    const maniKeys = Object.keys(error);
    if (maniKeys?.length > 0) {
        if (Array.isArray(error[maniKeys[0]])) {
            msg = error[maniKeys[0]][0];
        } else {
            msg = getStringError(error[maniKeys[0]]);
        }
    }
    return msg;
}
export const saudiMobilePattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
export const globalPhoneNumberPattern = /^(\+|00)*[٠-٩0-9 ]{10,12,15}$/;
export const arabicNumberToEnglish = (number:string) =>{
    if (typeof number !== 'string') {
        return number;
    }
    number=number?.replace(" ","");
    const arabicMap: any = {
        '٠': 0,
        '١': 1,
        '٢': 2,
        '٣': 3,
        '٤': 4,
        '٥': 5,
        '٦': 6,
        '٧': 7,
        '٨': 8,
        '٩': 9
    };
    return number.split('').map((n: any) => arabicMap[n] !== undefined ? arabicMap[n] : n).filter((itm:any)=>itm).join('');
} 
export const removeNoneNumeric = (text:string) =>{
    // return only English numiric function
    if (typeof text !== 'string') {
        return text;
    }
    const arabicMap: any = {
        "٠": 0,
        "١": 1,
        "٢": 2,
        "٣": 3,
        "٤": 4,
        "٥": 5,
        "٦": 6,
        "٧": 7,
        "٨": 8,
        "٩": 9,
        "0":0,
        "1":1,
        "2":2,
        "3":3,
        "4":4,
        "5":5,
        "6":6,
        "7":7,
        "8":8,
        "9":9
    };
    let formatedTxt ="";
    text.split('').forEach((itm:string)=>{
        if(arabicMap?.[itm] || arabicMap?.[itm]===0){
            formatedTxt+=arabicMap?.[itm]
        }
    });
    return formatedTxt
}