import "./home.style.scss";
import HeroHeaderSection from "./partials/hero-header-section.ui";
import SponsorsIconsSection from "./partials/sponsors-icons-section.ui";
import UnlockPotentialSection from "./partials/unlock-potential-section.ui";
import PotentialItemsSection from "./partials/potential-items-section.ui";
import StepsToVehicles from "./partials/steps-to-vehicles-section.ui";
import ChooseVehiclesSection from "./partials/choose-vehicles-section.ui";
import IndividualOffersSection from "./partials/individual-offers-section.ui";
import BusinessOffersSection from "./partials/business-offers-section.ui";
import CarouselCardsSection from "./partials/carousel-cards-section.ui";
import DownloadAppSection from "./partials/download-app-section.ui";
import FooterSection from "../../shared/components/footer-section/footer-section.ui";
// import AppSmartBanner from "../public/onboarding/partials/smart-banner";

const HomePage = () => {
    return (
        <>
            {/* <AppSmartBanner /> */}
            <HeroHeaderSection />
            <main>
                <SponsorsIconsSection />
                <UnlockPotentialSection />
                <PotentialItemsSection />
                <StepsToVehicles />
                <ChooseVehiclesSection />
                <IndividualOffersSection />
                <BusinessOffersSection />
                <CarouselCardsSection />
                <DownloadAppSection />
            </main>
            <FooterSection />
        </>
    );
};

export default HomePage;
