import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import GetStartedModel from "./get-started.model";
import { Button, Col, Form, Image, Input, Row, Select, Typography } from "antd";
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { arabicNumberToEnglish, removeNoneNumeric } from '../../shared/utils/helper';
import P1 from '../../assets/imgs/P1.png';
import P2 from '../../assets/imgs/P2.png';
import P3 from '../../assets/imgs/P3.png';
import Mobile_logo from '../../assets/imgs/thrivve-logo.svg';
import Mobile_logo_ar from '../../assets/imgs/thrivve-logo-ar.svg';
import 'react-phone-input-2/lib/style.css'
import ResultModal from "./partials/ResultModal";
import TermsPage from "./partials/TermsPage";
import "./get-started.style.css";

let picInterval: any = null;

function GetStartedUi({ creating, registerLead }: any) {
    const [sidePic, setSidePic] = useState<any>(P1);
    const [page, setPage] = useState<string>("registerPage");
    const [creatingResult, setCreatingResult] = useState<any>(null);
    const [resultModal, setResultModal] = useState<any>(false);
    const [phoneNumber, setPhoneNumber] = useState<any>("+966");
    const { t, i18n }: any = useTranslation();
    const [form] = Form.useForm();
    const handleFormSubmit = (values: any) => {
        values.captain_mobile = arabicNumberToEnglish(values?.captain_mobile);
        values.national_id = arabicNumberToEnglish(values?.national_id);
        registerLead(values).then((resp: any) => {
            if (resp) {
                setCreatingResult(resp);
                setResultModal(true);
            }
        });
    }
    const onIdChange = (e: any) => {
        let formattedValue = removeNoneNumeric(e?.target?.value);
        formattedValue = arabicNumberToEnglish(formattedValue);
        form.setFieldsValue({ national_id: formattedValue });
    }
    const onNumberChange = (e: any) => {
        if (e?.target?.value?.length <= 4) {
            setPhoneNumber('+966');
            form.setFieldsValue({ captain_mobile: '966' });
            return
        }
        let formattedValue: any = e?.target?.value?.replace("+966", "");
        formattedValue = removeNoneNumeric(formattedValue);
        // formattedValue = arabicNumberToEnglish(formattedValue);
        if (formattedValue.replaceAll(" ", "")?.length >= 10) {
            return
        }
        let str: any = formattedValue.split(" ").join("").split("");
        const formatted: any = [];
        while (str.length) {
            for (let i = 0; i < 3 && str.length; i++) {
                formatted.push(str.shift());
            }
            if (str.length) formatted.push(" ");
        }
        setPhoneNumber("+966 " + formatted.join(""));
        form.setFieldsValue({ captain_mobile: "966" + formatted.join("").replaceAll(" ", "") });
    }
    const changeSideBackGroundImage = () => {
        let images = [P1, P2, P3];
        clearInterval(picInterval);
        picInterval = setInterval(() => {
            let randomIndex = Math.floor(Math.random() * images.length)
            let bg = images[randomIndex];
            setSidePic(bg);
        }, 60000);
    }
    const workingExpOp = [
        { label: t("noExperience"), value: 'لا يوجد خبرة سابقة' },
        { label: t("1 - 3 Months"), value: '1 - 3 شهور' },
        { label: t("3 - 6 Months"), value: '3 - 6  شهور' },
        { label: t("More Than 6 Months"), value: 'أكثر من 6 شهور' },

    ];
    const averageWorkingHoursOp = [
        { label: t("1 - 15"), value: '1 - 15' },
        { label: t("15 - 30"), value: '15 - 30' },
        { label: t("More Than 30 Hours"), value: '30+' },
    ]
    useEffect(() => {
        changeSideBackGroundImage();
        return () => clearInterval(picInterval);
    }, []);
    return (
        <Row className="LedReg">
            <Col sm={8} xs={0} className={"LedReg_Sider"}>
                <div className={"LedReg_Sider_Relative"}>
                    <div className="LedReg_Logo_container" />
                    <Image src={sidePic} alt={"Advertize picture"} preview={false} />
                    {/* <div className="LedReg_Sider_inner" id={"Led_Reg_sider_background_img"} /> */}
                </div>
            </Col>
            <Col flex={"1"} >
                <Row align={'middle'} justify={"center"} className={"LedReg_Form_Outer relativePosition"}>
                    <Col lg={0} md={0} sm={24} xs={24} className={"LedReg_mobile_logo"}>
                        <Row justify={"center"}>
                            <Col>
                                <Image src={i18n?.dir() === "ltr" ? Mobile_logo : Mobile_logo_ar} alt="WeDeliver Logo" preview={false} />
                            </Col>
                            <Col span={24} className="confirm-info-title">
                                <Typography.Title level={3}>{t("Confirm Your Contact Info")}</Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={16} sm={22} xs={22}>
                        {
                            page !== "terms"
                                ? <Form
                                    labelAlign="left"
                                    layout="vertical"
                                    colon={false}
                                    onFinish={handleFormSubmit}
                                    className={`LedReg_Form`}
                                    form={form}
                                // requiredMark={false}
                                // initialValues={{
                                //     captain_mobile: "+٩٩"
                                // }}
                                >
                                    <Form.Item
                                        name={"captain_name"}
                                        labelCol={{ span: 24 }}
                                        label={
                                            <span>{t("Full name")}:</span>
                                        }
                                    // rules={[{ required: true, message: t("req_message") }]}
                                    >
                                        <Input className="Custom_Form_Input" />
                                    </Form.Item>
                                    <Form.Item
                                        name={"captain_mobile"}
                                        labelCol={{ span: 24 }}
                                        label={
                                            <span>
                                                {t("Phone Number")}
                                            </span>
                                        }
                                        rules={[
                                            { required: true, message: t("requiredPhoneNumber") }
                                        ]}
                                        className={"LedReg_flag_mobile"}
                                    >
                                        <PhoneInput
                                            inputStyle={{
                                                width: '100%',
                                                height: '48px',
                                                backgroundColor: '#F5F5F5',
                                                border: '1px solid #dfecf7',
                                                borderColor: '#dfecf7',
                                                direction: 'ltr',
                                            }}
                                            inputProps={{
                                                type: "tel",
                                                inputMode: "numeric",
                                                onChange: onNumberChange,
                                                value: phoneNumber
                                            }}
                                            // onChange={onNumberChange}
                                            country={"sa"}
                                            // inputClass={"flagZoom"}
                                            onlyCountries={["sa"]}
                                            countryCodeEditable={false}
                                            disableDropdown={true}
                                            buttonStyle={{ borderRightWidth: 0, borderBottomLeftRadius: 5, borderTopLeftRadius: 5, backgroundColor: '#F5F5F5' }}
                                        />
                                        {/* <Input className="Custom_Form_Input" onChange={onNumberChange} type="tel" inputMode="numeric" /> */}
                                    </Form.Item>
                                    <Form.Item
                                        name={"national_id"}
                                        labelCol={{ span: 24 }}
                                        label={
                                            <span>{t("ID number")}</span>
                                        }
                                        rules={[
                                            // { required: true, message: t("req_message") },
                                            { pattern: /^[٠-٩0-9]+$/i, message: t("IDonlyNumber") }
                                        ]}
                                    >
                                        <Input className="Custom_Form_Input" onChange={onIdChange} type="tel" inputMode="numeric" />
                                    </Form.Item>
                                    <Form.Item
                                        name={"working_time"}
                                        label={<span>{t("expField")}</span>}
                                    // rules={[{ required: true, message: t("req_message") }]}
                                    >
                                        <Select
                                            options={workingExpOp}
                                            optionLabelProp="label"
                                            className="Custom_Form_Select"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={"average_working_time_type"}
                                        label={
                                            <span>{t("weeklyWorAvgHours")}</span>
                                        }
                                    // rules={[{ required: true, message: t("req_message") }]}
                                    >
                                        <Select
                                            options={averageWorkingHoursOp}
                                            optionLabelProp="label"
                                            className="Custom_Form_Select"
                                        />
                                    </Form.Item>
                                    <Button size="large" loading={creating} block type="primary" htmlType="submit" className={"Terms_Reg_button register"}>{t("Register")}</Button>
                                </Form>
                                : <TermsPage OnRegisterClick={() => setPage("registerPage")} />
                        }
                        <ResultModal IsOpen={resultModal} data={creatingResult} onClose={() => setResultModal(false)} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = ({ GetStarted }: any) => {
    const { creating } = GetStarted;
    return { creating };
};
const mapDispatchToProps = (dispatch: any) => ({
    registerLead: (data: any) => dispatch(GetStartedModel.registerLeadAction(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(GetStartedUi);