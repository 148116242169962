import React from "react";
import { Col, Divider, Row, Image, Button } from "antd";
import GreenCheckPoint from '../../../assets/imgs/GreenCheckPoint.svg';

function TermsPage({ OnRegisterClick }: any) {
    return (
        <Row gutter={[0, 24]}>
            <Col span={24} className="Terms_outer_wrapper">
                <Row className="Terms_outer" gutter={[0, 16]}>
                    <Col>
                        <Row gutter={[0, 16]}>
                            <Col span={24} className="CenterText">
                                <span className="title_text">
                                    عرض خاص لكباتن كريم الحاليين
                                </span>
                            </Col>

                            <Col span={24} className="CenterText">
                                <span className="sub_title_text">
                                    لأول مرة في السعودية, وي ديلفر تقدم لك سيارة والتي تستطيع تسديدها من خلال عملك مع كريم لتحقيق دخل إضافي ممتاز
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider style={{ margin: 0 }} />
                    </Col>
                    <Col span={24}>
                        <span className="title_text">المزايا</span>
                    </Col>
                    <Col span={24}>
                        <Row align={"middle"} gutter={8}>
                            <Col>
                                <Image src={GreenCheckPoint} alt="Wedeliver Logo" preview={false} />
                            </Col>
                            <Col>
                                <span className="point_text">
                                    أقساط أسبوعية ميسرة
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row align={"middle"} gutter={8}>
                            <Col>
                                <Image src={GreenCheckPoint} alt="Wedeliver Logo" preview={false} />
                            </Col>
                            <Col>
                                <span className="point_text">
                                    بدون كفيل
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row align={"middle"} gutter={8}>
                            <Col>
                                <Image src={GreenCheckPoint} alt="Wedeliver Logo" preview={false} />
                            </Col>
                            <Col>
                                <span className="point_text">
                                    دفعة أولى تبدأ من 3000 ريال فقط
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row align={"middle"} gutter={8}>
                            <Col>
                                <Image src={GreenCheckPoint} alt="Wedeliver Logo" preview={false} />
                            </Col>
                            <Col>
                                <span className="point_text">
                                    تأمين شامل
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row align={"middle"} gutter={8}>
                            <Col>
                                <Image src={GreenCheckPoint} alt="Wedeliver Logo" preview={false} />
                            </Col>
                            <Col>
                                <span className="point_text">
                                    برنامج حوافز شهريا حسب الأداء
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider style={{ margin: 0 }} />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 8]}>
                            <Col span={24}>
                                <span className="title_text">الشروط والأحكام</span>
                            </Col>
                            <Col span={24}>
                                <span className="condition_dot">
                                    .
                                </span>
                                <span className="condition_text">
                                    سعودي/ـة وعمرك بين 21 و 50 سنة
                                </span>
                            </Col>
                            <Col span={24}>
                                <span className="condition_dot">
                                    .
                                </span>
                                <span className="condition_text">
                                    عمل على إحدى تطبيقات توجيه المركبات المرخصة بالمملكة بما لا يقل عن 6 شهور بمعدل أسبوعي لا يقل  15 ساعة ، أو ترغب ببدء العمل مع كريم بمعدل لا يقل عن 30 ساعة أسبوعية
                                </span>
                            </Col>
                            <Col span={24}>
                                <span className="condition_dot">
                                    .
                                </span>
                                <span className="condition_text">
                                    لا يشترط أن يكون لديك مصدر دخل آخر أو وظيفة رسمية
                                </span>
                            </Col>
                            <Col span={24}>
                                <span className="condition_dot">
                                    .
                                </span>
                                <span className="condition_text">
                                    لا يشترط تقديم وجود تعريف بالراتب
                                </span>
                            </Col>
                            <Col span={24}>
                                <span className="condition_dot">
                                    .
                                </span>
                                <span className="condition_text">
                                    إمكانية التسجيل عبر التأمينات الاجتماعية وتوفير تأمين صحي للكابتن الراغبين بالعمل بنظام الدوام الكامل ( بمعدل 40 ساعة أسبوعياً وأكثر) مع مجموعة حوافز أخرى
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Button type="primary" block onClick={OnRegisterClick} className={"Terms_Reg_button"}>
                    سجل الأن
                </Button>
            </Col>
        </Row>
    );
}
export default TermsPage;