// import moment from "moment";
import { message } from "antd";
import call from "../../shared/services/api-service";
import { getStringError } from "../../shared/utils/helper";
import {
    // LOADING_LOVS, LOVS_LOADED, 
    UPDATE_CREATING_LEAD,
    // UPDATE_LOADING_COUNTRIES, COUNTRIES_LOADED
} from './get-started.action'
class GetStartedModel{
    // public static getLovsAction() {
    //     return async (dispatch: any) => {
    //         let result: any = {};
    //         dispatch({
    //             type: LOADING_LOVS,
    //             payload: true
    //         });
    //         try {
    //             result = await call({
    //                 path: `/api/v1/tamwheel/lovs/`,
    //                 method: "GET",
    //             },"finTech");
    //             if(result){
    //                 dispatch({
    //                     type: LOVS_LOADED,
    //                     payload: getFormattedLovs(result??{})
    //                 });
    //                 return getFormattedLovs(result);
    //             }
    //         } catch (e) {
    //             dispatch({
    //                 type: LOADING_LOVS,
    //                 payload: false
    //             });
    //             return {
    //                 isError: true,
    //                 e
    //             };
    //         }
    //     };
    // }
    // public static getAllCountriesAction(){
    //     return async (dispatch: any) => {
    //         let result:any = [];
    //         dispatch({
    //             type: UPDATE_LOADING_COUNTRIES,
    //             payload: true
    //         });
    //         try {
    //             result = await call({
    //                 path:"/api/v1/location/get_all_abstract_countries",
    //                 method: "GET",
    //             });
    //             if(result){
    //                 result= result.map((obj:any)=>{
    //                     obj.value= obj?.id;
    //                     obj.label= obj?.name_en;
    //                     return obj;
    //                 });
    //                 dispatch({
    //                     type: COUNTRIES_LOADED,
    //                     payload: result
    //                 });
    //                 return result;
    //             }
    //         } catch (e) {
    //             dispatch({
    //                 type: UPDATE_LOADING_COUNTRIES,
    //                 payload: false
    //             });
    //             return{
    //                 isError:true,
    //                 e
    //             }
    //         }
    //     }
    // }
    // public static getCitiesAction(search_txt:any,country_id:any=undefined) {
    //     return async () => {
    //         let result: any = {};
    //         try {
    //             result = await call({
    //                 path: `/api/v1/location/get_all_abstract_global_cities`,
    //                 method: "GET",
    //                 data:{search_txt,country_id}
    //             },"sdd");
    //             if(result){
    //                 result = result.map((obj:any)=>(
    //                     {
    //                         value:obj.id,
    //                         label: obj.name
    //                     }
    //                 ));
    //                 return result;
    //             }
    //         } catch (e) {
    //             return {
    //                 isError: true,
    //                 e
    //             };
    //         }
    //     };
    // }
    public static registerLeadAction(data:any) {
        return async (dispatch: any) => {
            let result: any = {};
            dispatch({
                type: UPDATE_CREATING_LEAD,
                payload: true
            });
            try {
                result = await call({
                    path: `/api/v1/lead/uber/public`,
                    method: "POST",
                    data
                },"core");
                if(result){
                    if(!result?.status){
                        message.error(result?.message);
                        return false;
                    }
                    return result;
                }
            } catch (e) {
                message.error(getStringError(e));
                return false;
            }finally{
                dispatch({
                    type: UPDATE_CREATING_LEAD,
                    payload: false
                });
            }
        };
    }
}
export default GetStartedModel;

// const getFormattedLovs = (lovs:any) =>{
//     const formattedLovs:any = {};
//     for(let key in lovs){
//         formattedLovs[key] = lovs[key]?.map((obj:any)=>{
//             return{
//                 label: obj?.Value,
//                 value: obj?.id
//             }
//         })
//     }
//     return formattedLovs??{};
// }