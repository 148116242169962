import React from "react";
import { Button, Col, Divider, Image, Modal, Row } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Mobile_logo from '../../../assets/imgs/thrivve-logo.svg';
import "../get-started.style.css";
import { Link } from "react-router-dom";
import ROUTES_ENUM from "../../../shared/routes-enum";


function ResultModal({ IsOpen, data, onClose }: any) {
    const { t }: any = useTranslation();
    return (
        <Modal
            open={IsOpen}
            centered={true}
            closable={false}
            footer={null}
            className="confirm-info-result-modal"
            // title={"test"}
            onCancel={onClose}
        >
            <Row align={"top"} justify="center" className="LedReg_Result" gutter={[0, 32]}>
                <Col span={24} className="CenterText">
                    <Row align={"middle"} justify={"space-between"}>
                        <Col>
                            <Button
                                className="modal-close-btn" icon={<CloseOutlined />}
                                onClick={onClose}
                            />
                        </Col>
                        <Col>
                            <Image src={Mobile_logo} alt="Thrivve Logo" preview={false} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Divider style={{ marginTop: 16, marginBottom: 0 }} />
                </Col>
                <Col span={24} className="CenterText">
                    <span className="title_text">{t("Thank you for submitting your information!")}</span>
                </Col>
                <Col span={24} className="CenterText">
                    <p className="desc_text">
                        {t("We will contact you within 5 working days. If you have any questions in the meantime, feel free to reach out to us via WhatsApp:")}
                        <a
                            rel="noreferrer" href="https://wa.me/966531110775" target={"_blank"}
                        >
                            <p style={{ marginBottom: 0, color: "#000" }}>
                                966531110775+
                            </p>
                        </a>
                    </p>
                </Col>
                <Col>
                    <Link to={ROUTES_ENUM.BASE}>
                        <Button className="goHomeBtn">{t("Go To Home")}</Button>
                    </Link>
                </Col>
            </Row>
        </Modal>
    );
}
export default ResultModal;